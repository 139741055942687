<template>
  <div>
    <a-button type="primary" @click="$router.push('/personal/basic/edit')">编辑</a-button>
    <div class="gpv-10 gb-ccc gtc gfw-b gmv-15">基本信息</div>
    <a-row>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">性别</div>
          <div>{{['女', '男', '保密'][userInfo.sex]}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">出生日期</div>
          <div>{{userInfo.birthday}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">籍贯</div>
          <div>{{userInfo.jiguan}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">民族</div>
          <div>{{userInfo.minzu}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">身份证号</div>
          <div>{{userInfo.id_card}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gmb-20 g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">入学年月</div>
          <div>{{userInfo.rxny}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">政治面貌</div>
          <div>{{userInfo.zzmm}}</div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="g-flex">
          <div class="gmr-10 gfw-b gw-100p gtr">通讯地址</div>
          <div>{{userInfo.address}}</div>
        </div>
      </a-col>
    </a-row>
    <div class="gpv-10 gb-ccc gtc gfw-b gmv-15">家庭主要成员</div>
    <a-table :columns="column" :data-source="tableData" bordered :pagination="false">
    </a-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      column: [
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          align: 'center',
        },
        {
          title: '年龄',
          dataIndex: 'age',
          scopedSlots: { customRender: 'age' },
          align: 'center',
        },
        {
          title: '关系',
          dataIndex: 'guanxi',
          scopedSlots: { customRender: 'guanxi' },
          align: 'center',
        },
        {
          title: '政治面貌',
          dataIndex: 'zzmm',
          width: 300,
          scopedSlots: { customRender: 'zzmm' },
          align: 'center',
        },
        {
          title: '联系方式',
          dataIndex: 'mobile',
          width: 300,
          scopedSlots: { customRender: 'mobile' },
          align: 'center',
        },
        {
          title: '工作地址',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          align: 'center',
        },
        {
          title: '职务',
          dataIndex: 'zhiwu',
          scopedSlots: { customRender: 'zhiwu' },
          align: 'center',
        },
      ],
      tableData: [],
    }
  },
  computed: {
    userInfo() {
      let userInfo = this.$store.getters.userInfo
      this.tableData = userInfo.family_list.filter((item) => item.name)
      return userInfo
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-table-thead > tr > th {
  background: #fff;
  font-weight: 700;
}
</style>